<template></template>

<script>
//正式AppId
const appId = "wxd7392a9695c1a4ec";
const urlPrefixNoAdmin = "https://api.szszg.cn"; //正式api地址
//    const urlPrefixNoAdmin = "http://t.szlsyjsq.com"; //测试api地址
export default {
  appId,
  urlPrefixNoAdmin,
};
</script>

<style scoped></style>
